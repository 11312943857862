/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.














jQuery(document).ready(function($) {
	
	// MAIN MENU DROP DOWNS
	$("ul#menu-primary-navigation li.menu-item-has-children").mouseenter(function() {
		$(this).children(".sub-menu").stop();
		$(this).children(".sub-menu").slideDown("fast");
	});
	$("ul#menu-primary-navigation li.menu-item-has-children").mouseleave(function() {
		$(this).children(".sub-menu").stop();
		$(this).children(".sub-menu").slideUp("fast");
	});
	
	
	// EXPANDERS
	$(".trigger").click(function() {
		
		$(".expander").removeClass("active");
		$(".expander-content").slideUp('fast');
		
		$(this).parent(".expander").addClass("active");
		$(this).siblings(".expander-content").slideDown('fast');
	});
	
	$("a.bio-trigger").click(function() {
		console.log("click");
	});
	
	$(".bio-trigger").colorbox({
			inline: true, 
			width: "100%", 
			maxWidth: "800px",
			opacity: .7,
		
	});
	
	
	// MOBILE NAV
	$(".mobile_menu").click(function() {
		$("#menu-primary-navigation").slideToggle('fast');
	});
	
	$("#menu-primary-navigation a").click(function() {
		$("#menu-primary-navigation").slideUp('fast');
	});
	
	
	$(window).scroll(function (event) {
	    var scroll = $(window).scrollTop();
	    if (scroll > 20) {
		    $(".site-logo").addClass("little");
	    } else {
		    $(".site-logo").removeClass("little");
	    }
	});
	
	
	$(window).load(function(){
	    // Remove the # from the hash, as different browsers may or may not include it
	    var hash = location.hash.replace('#','');
	
	    if(hash == 'news'){
	
			// Clear the hash in the URL
			// location.hash = '';   // delete front "//" if you want to change the address bar
			$('html, body').animate({ scrollTop: $('#recent-news').offset().top}, 1000);
	
	    }
	
	    if(hash == 'about-us'){
	
			// Clear the hash in the URL
			// location.hash = '';   // delete front "//" if you want to change the address bar
			$('html, body').animate({ scrollTop: $('#about').offset().top  - 80}, 1000);
	
	    }
	
	    if(hash == 'our-team'){
	
			// Clear the hash in the URL
			// location.hash = '';   // delete front "//" if you want to change the address bar
			$('html, body').animate({ scrollTop: $('#team').offset().top  - 100}, 1000);
	
	    }
	});
	
	
	$("body.home .home-anchor a").click(function() {
		var hash = $(this).prop("hash").replace('#','');
		console.log(hash);
	
	    if(hash == 'news'){
	
			// Clear the hash in the URL
			// location.hash = '';   // delete front "//" if you want to change the address bar
			$('html, body').animate({ scrollTop: $('#recent-news').offset().top}, 1000);
	
	    }
	
	    if(hash == 'about-us'){
	
			// Clear the hash in the URL
			// location.hash = '';   // delete front "//" if you want to change the address bar
			$('html, body').animate({ scrollTop: $('#about').offset().top  - 80}, 1000);
	
	    }
	
	    if(hash == 'our-team'){
	
			// Clear the hash in the URL
			// location.hash = '';   // delete front "//" if you want to change the address bar
			$('html, body').animate({ scrollTop: $('#team').offset().top  - 100}, 1000);
	
	    }
	});
	
	
	$(function() {
		// init controller
		var controller = new ScrollMagic.Controller();
		
		// build scene
		var serviceTween = new TweenMax.staggerFrom('body.home #services .col', .5, {
		    scale: .8,
		    opacity: 0,
		    ease:  Power4.easeOut
		}, .1);
	

		var servicesScene = new ScrollMagic.Scene({triggerElement: "body.home #services"})
						.setTween(serviceTween)
						.addTo(controller);	
		
	
	});
	
	
});
